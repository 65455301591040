import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import composeValidators from '../../validators/composeValidators';

// TODO: move this function to the validators folder
const isRequired = (isRequired) =>
  isRequired
    ? (value) =>
        value === null || value === undefined || value.length === 0
          ? 'This value is required'
          : undefined
    : () => {};

const NOT_OPTIONAL_LABEL_TYPES = ['checkbox', 'radio', 'hidden'];

/**
 *
 * @NOTICE
 *
 * This component is not intended to be used outside of this folder,
 * it is intended to help build abtractations of <Fields /> so that
 * every abstraction has the same basic logic.
 *
 * In other words, if you are not creating a field component you
 * should NOT be using this component. You should be using one of the
 * other components, such as:
 *
 * <InputField />
 * <SelectField />
 * <TextAreaField />
 * <MoneyField />
 *
 * Among others...
 *
 */
export const CoreField = ({
  id,
  name,
  key,
  label,
  type,
  required = true, // by default fields are required
  validate = [], // validate can be a function or an array of functions
  withOptionalLabel = true,
  parse = (value) => value,
  ...props
}) => {
  return (
    <Field
      id={id || name}
      name={name}
      label={label}
      hint={
        withOptionalLabel && !NOT_OPTIONAL_LABEL_TYPES.includes(type)
          ? '(Optional)'
          : null
      }
      parse={parse}
      type={type}
      validate={composeValidators(
        isRequired(required !== false),
        ...(Array.isArray(validate) ? [...validate] : [validate]),
      )}
      key={key !== null && key !== undefined ? `${key}_${required}` : required}
      {...props}
    />
  );
};

CoreField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  key: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  withOptionalLabel: PropTypes.bool,
  parse: PropTypes.func,
};

export default React.memo(CoreField);
