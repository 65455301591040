import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import reihubPoweredBy from '../../../assets/reihub/powered-by.svg';
import colors from '../../../constants/colors';
import userReiHubPricing from '../../../core/hooks/userReiHubPricing';
import getReiHubPrice from '../../../helpers/getReiHubPrice';
import CheckMarkFilled from '../../../icons/CheckMarkFilled';
import Button from '../../Button';
import HelpfulInfoList from '../../HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../HelpfulInfo/HelpfulInfoListItem';
import FiveStarsImage from '../../ReiHubSubscription/ReiHubInfoTopCardTest/assets/FiveStars';
import ReviewCountImage from '../../ReiHubSubscription/ReiHubInfoTopCardTest/assets/ReviewCount';
import GoogleReviewsCard from '../../ReiHubSubscription/ReiHubInfoTopCardTest/GoogleReviewsCard';
import { googleReviews } from '../../ReiHubSubscription/ReiHubInfoTopCardTest/helpers';
import Stepper from '../../Stepper';
import Tag from '../../Tag';
import { H3, Paragraph } from '../../Typography';

import styles from './ReiHubOfferModalContentTest.module.scss';

const listItems = [
  'Simplify your accounting with a tool tailored for your rental business',
  'Tax-ready Schedule E and seamless reporting',
  'Automated revenue and expense tracking',
  'Expert customer support',
];

const ReiHubOfferModalContentTest = ({
  unitCount,
  setUnitCount,
  listingsCount,
  onClaimDiscount,
  onNoInterested,
}) => {
  const pricingMap = userReiHubPricing();
  const reiHubPriceData = getReiHubPrice(pricingMap, unitCount);
  let price = reiHubPriceData?.price;

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.leftSide}>
        <Tag accent small uppercase={false} className={styles.tag}>
          Limited Time Offer
        </Tag>

        <H3 className="mb-0">
          Relax this tax season and{' '}
          <span className={styles.savePrice}>
            save ${reiHubPriceData?.savings}
          </span>{' '}
          with TurboTenant Accounting!
        </H3>

        <HelpfulInfoList className={styles.offerListWrapper}>
          {listItems.map((item) => (
            <HelpfulInfoListItem
              icon={CheckMarkFilled}
              iconProps={{ color: colors.primary }}
              className={styles.listItem}
              key={item}
            >
              {item}
            </HelpfulInfoListItem>
          ))}
        </HelpfulInfoList>

        <div className={styles.priceHolder}>
          <Paragraph className={styles.priceWrapper}>
            <span className={styles.oldPrice}>
              ${reiHubPriceData?.slashedPrice}
            </span>
            <span className={styles.price}>${price}</span>
            <span className={styles.priceType}>per year</span>
          </Paragraph>
        </div>

        <Stepper
          input={{
            value: unitCount,
            onChange: (val) => setUnitCount(val),
          }}
          id="stepper"
          label="How many units do you own or manage?"
          small
          meta={{
            touched: false,
            error: '',
          }}
          min={listingsCount}
        />

        <div className={styles.offerFooter}>
          <Button
            secondary
            onClick={onNoInterested}
            data-qa="not-interested-btn"
            className={styles.btn}
          >
            Not now
          </Button>
          <Button onClick={onClaimDiscount} className={styles.btn}>
            Claim discount
          </Button>
        </div>

        <div>
          <Paragraph size="large" className={styles.paragraph} italic>
            Offer expires in 7 days on{' '}
            {moment().add(7, 'days').format('MMMM D, YYYY')}.
          </Paragraph>
        </div>
      </div>

      <div className={styles.rightSide}>
        <div className={styles.reviewsHeader}>
          <FiveStarsImage />
          <ReviewCountImage />
        </div>
        <div className={styles.reviewsContainer}>
          {googleReviews
            .slice(0, 3)
            .map(({ id, link, image, name, units, review, rating }) => (
              <GoogleReviewsCard
                key={id}
                {...{ link, image, name, units, review, rating }}
              />
            ))}
        </div>
        <img className={styles.reihubPoweredByImage} src={reihubPoweredBy} />
      </div>
    </div>
  );
};

ReiHubOfferModalContentTest.propTypes = {
  unitCount: PropTypes.number.isRequired,
  setUnitCount: PropTypes.func.isRequired,
  listingsCount: PropTypes.number.isRequired,
  onClaimDiscount: PropTypes.func,
  onNoInterested: PropTypes.func,
};

export default ReiHubOfferModalContentTest;
