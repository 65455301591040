import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import basicUserProfile from '../../../graphql/basicUserProfile.graphql';
import ownerSettingsQuery from '../../../graphql/queries/ownerSettingsQuery.graphql';
import { useRenderOutside } from '../../../helpers/render-outside';
import useWootricSDK from '../../../hooks/useWootricSDK';
import AlarmFilled from '../../../icons/AlarmFilled';
import CheckFilled from '../../../icons/CheckFilled';
import NewWindow from '../../../icons/NewWindow';
import { useConfig } from '../../../providers/ConfigProvider';
import { useGlobalBanner } from '../../../providers/GlobalBannerProvider';
import { segmentTracking } from '../../../services/utilities';
import Badge from '../../Badge';
import TTLogo from '../../Logo';
import PremiumSubscriptionModal from '../../PremiumSubscription';
import { MODAL_LOCATIONS } from '../../PremiumSubscription/constants';
import MenuOfferTag from '../MenuOfferTag/MenuOfferTag';

import styles from './DesktopNavigation.module.scss';

const DesktopNavigation = ({
  user,
  location,
  isOwner,
  isPremiumUser,
  userDataLoaded,
  navItems,
  navBottomItems,
  unreadMessagesCount,
  isUserSubscribedToREIHub = false,
  showSubscriptionAlert,
}) => {
  const renderOutside = useRenderOutside();
  const config = useConfig();
  const { hasBanner: hasGlobalBanner } = config.IS_ADMIN
    ? {}
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useGlobalBanner();
  const history = useHistory();

  if (isOwner) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useWootricSDK({
      config,
      email: user.email,
      created_at: user.created_at,
    });
  }

  const renderNavItem = ({
    Icon,
    IconProps = {},
    SecondaryIcon,
    SecondaryIconProps,
    to,
    href,
    onClick,
    label,
    id,
    isActive,
    isActiveFilter,
    isInactiveFilter,
    subItems,
    subMenuRef,
  }) => {
    let active = location.pathname.indexOf(isActiveFilter) !== -1 || isActive;

    if (
      isInactiveFilter &&
      isInactiveFilter
        .split(',')
        .some((route) => location.pathname.indexOf(route) !== -1)
    ) {
      active = false;
    }
    const hasOptions = subItems && !subMenuRef;

    let Element;
    const linkProps = {};

    if (to) {
      // eslint-disable-next-line no-useless-assignment
      Element = Link;
      linkProps.to = to;
    } else if (href) {
      // eslint-disable-next-line no-useless-assignment
      Element = 'a';
      linkProps.href = href;
      linkProps.target = '_blank';
    } else {
      // eslint-disable-next-line no-useless-assignment
      Element = 'span';
    }

    return (
      <Element
        to={to}
        key={`desktop-nav-item-${id}`}
        className={cx({
          [styles.navItem]: !hasOptions,
          [styles.account]: id?.endsWith('account'),
          [styles.active]: active,
          [styles.hasSecondaryIcon]: SecondaryIcon,
          [styles.hasOptions]: hasOptions,
        })}
        onClick={() => {
          onClick && onClick();
        }}
        {...linkProps}
        data-qa={`desktop-nav-item-${id}`}
        {...(subMenuRef?.itemRef ? { ref: subMenuRef.itemRef } : {})}
      >
        {subItems && subMenuRef && (
          <div
            {...(subMenuRef.ref ? { ref: subMenuRef.ref } : {})}
            style={subMenuRef?.position}
            className={cx(styles.menuSubMenu, {
              [styles.active]: subMenuRef.show,
              [styles.wide]: id === 'mua-account',
            })}
          >
            {subItems.map(renderNavItem)}
          </div>
        )}

        {!hasOptions && (
          <div className={styles.iconWrap}>
            <Icon
              color={colors.greyLight}
              width={24}
              height={24}
              className={cx({
                [styles.profilePicture]: id === 'mua-account',
                [styles.referralIcon]: id === 'referrals',
              })}
              {...IconProps}
            />
          </div>
        )}
        <div
          className={styles.label}
          title={id?.includes('account-') && label.length > 19 ? label : null}
        >
          {label}
        </div>

        {isActive && <CheckFilled className={styles.check} />}
        {SecondaryIcon && (
          <div className={styles.secondaryIconWrap}>
            <SecondaryIcon {...SecondaryIconProps} />
          </div>
        )}
        <Badge
          {...(id === 'messages' && unreadMessagesCount
            ? { counter: unreadMessagesCount }
            : {})}
          badgeClassName={styles.badge}
        />
        {(id === 'accounting' || id === 'reports') &&
          isUserSubscribedToREIHub && (
            <NewWindow color="#ACB9C8" width={11.25} height={11.25} />
          )}
        {(id === 'accounting' || id === 'reports') && showSubscriptionAlert && (
          <AlarmFilled
            color={colors.orangeLight}
            exclamationColor={colors.white}
            width={16}
            height={16}
          />
        )}
        {hasOptions && (
          <div className={styles.options}>{subItems.map(renderNavItem)}</div>
        )}
      </Element>
    );
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.withGlobalBanner]: hasGlobalBanner,
        [styles.isOwnerNavigation]: isOwner,
        [styles.isRenterNavigation]: !isOwner,
      })}
    >
      <div className={styles.navigationWrapper}>
        <div
          className={cx(styles.logoWrap, {
            [styles.notPremium]: !userDataLoaded && !isPremiumUser,
          })}
        >
          {user && user.custom_logo ? (
            <img src={user.custom_logo} />
          ) : (
            <TTLogo color="#FFFFFF" />
          )}
        </div>
        <MenuOfferTag
          createdAt={user?.created_at}
          userDataLoaded={userDataLoaded}
          isOwner={isOwner}
          isPremiumUser={isPremiumUser}
          onClick={() => {
            segmentTracking(
              `${isPremiumUser ? 'give_25 clicked' : 'upgrade clicked'}`,
              {
                location: 'Nav Bar',
              },
            );

            isPremiumUser
              ? history.push(`${config.PRIVATE_BASE_PATH}referrals`)
              : renderOutside((done) => (
                  <PremiumSubscriptionModal
                    onClose={() => {
                      done();
                    }}
                    onSuccess={() => {
                      done();
                    }}
                    openLocation={MODAL_LOCATIONS.SIDEBAR_NAVIGATION_TAG}
                    refetchQueries={[basicUserProfile, ownerSettingsQuery]}
                  />
                ));
          }}
        />
        <nav
          className={cx(styles.itemsContainer, {
            [styles.renter]: !isOwner,
          })}
        >
          <div className={styles.itemsWrapper}>
            {navItems &&
              Object.keys(navItems).map((key, i) => {
                return (
                  <div key={`desktop-nav-item-wrap-${i}`}>
                    <span
                      className={cx(styles.catLabel, key.id, {
                        [styles.hide]: !key,
                      })}
                    >
                      {key}
                    </span>
                    {navItems[key].map(renderNavItem)}
                  </div>
                );
              })}
          </div>
          <div className={styles.fadeElement} />
          <div className={styles.navBottom}>
            {navBottomItems && navBottomItems.map(renderNavItem)}
          </div>
        </nav>
      </div>
    </div>
  );
};

DesktopNavigation.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object.isRequired,
  isOwner: PropTypes.bool,
  isPremiumUser: PropTypes.bool,
  userDataLoaded: PropTypes.bool,
  navItems: PropTypes.object.isRequired,
  navBottomItems: PropTypes.array,
  unreadMessagesCount: PropTypes.number,
  showSubscriptionAlert: PropTypes.bool,
  isUserSubscribedToREIHub: PropTypes.bool,
};

export default DesktopNavigation;
