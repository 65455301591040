import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import Checkbox from '../../../../../../components/Checkbox';
import DueDateField from '../../../../../../components/DueDateField/DueDateField';
import IconTooltip from '../../../../../../components/IconTooltip';
import Input from '../../../../../../components/Input';
import InputWithIcon from '../../../../../../components/InputWithIcon';
import {
  parseLateFeeBackendData,
  prepareLateFeeDataForSubmission,
} from '../../../../../../components/MonthlyChargeForm/utils/lateFeeDataHelpers';
import { prepareDate } from '../../../../../../components/MonthlyChargeForm/utils/prepareDate';
import prepareMonthlyChargeDates from '../../../../../../components/MonthlyChargeForm/utils/prepareMonthlyChargeDates';
import SelectField from '../../../../../../components/SelectField';
import {
  CHARGES_EXTENDED_DATE_FORMAT_UI,
  DATE_FORMATS_ARRAY,
  SQL_DATE_FORMAT,
} from '../../../../../../constants/charges_date_formats';
import { monthNamesOptions } from '../../../../../../constants/monthNamesOptions';
import monthlyChargeCategoryOptions from '../../../../../../constants/payment_request/monthly-charge-types';
import { yearListAsOptions } from '../../../../../../constants/yearOptions';
import convertCardinalToOrdinal from '../../../../../../helpers/cardinal-to-ordinal.js';
import DollarIcon from '../../../../../../icons/Dollar';
import PlusCircle from '../../../../../../icons/PlusCircle';
import leasePropTypes from '../../../../../../prop-types/lease';
import isUuid from '../../../../../../services/utilities/isUuid';
import composeValidators from '../../../../../../validators/composeValidators';
import fieldRequired from '../../../../../../validators/fieldRequired';
import fieldRequiredShort from '../../../../../../validators/fieldRequiredShort';
import isGreaterThan from '../../../../../../validators/isGreaterThan';
import isNumber from '../../../../../../validators/isNumber';
import { EnumRentChargeType } from '../../../../../enums';
import ConfigureLateFeesModal from '../../components/ConfigureLateFeeModal';
import LateFeeDigest from '../../components/ConfigureLateFeeModal/components/LateFeeDigest';

import styles from './MonthlyChargeForm.module.scss';

const MonthlyChargeForm = ({
  chargeInfo,
  onSubmit,
  onCancel,
  isFromNewFlow,
  isPremiumUser,
  bankAccountOptions,
  lease,
  hideDescription,
}) => {
  const isEdit = !!chargeInfo?.id || !isUuid(chargeInfo?.id);

  const [noEndDate, setNoEndDate] = useState(
    isUuid(chargeInfo?.id)
      ? chargeInfo?.no_end_date
      : chargeInfo?.id && !chargeInfo?.end_date,
  );
  const [isLateFeesModalOpen, setIsLateFeesModalOpen] = useState(false);
  const [lateFeeData, setLateFeeData] = useState({});

  const getInitialDueDay = (charge) => {
    const dueDate = charge?.due_day_of_month;
    if (dueDate === 'Last Day' || dueDate === '31' || dueDate === 31) {
      return 'Last Day';
    }

    if (dueDate) {
      return moment(dueDate, 'DD').date();
    }
    return 1;
  };

  const initialDueDay = getInitialDueDay(chargeInfo);

  useEffect(() => {
    if (chargeInfo?.late_fee_policy) {
      setLateFeeData(parseLateFeeBackendData(chargeInfo.late_fee_policy));
    }
  }, [chargeInfo?.late_fee_policy]);

  const {
    startDate,
    endDate,
    noEndDate: noEndDateCalculated,
  } = prepareMonthlyChargeDates(chargeInfo, lease);

  const [formData, setFormData] = useState({
    category:
      lease?.payment_request_rules_count === 0
        ? EnumRentChargeType.RENT
        : undefined,
    ...chargeInfo,
    due_day_of_month: initialDueDay,
    start_date: prepareDate({
      date: startDate,
      due_day_of_month: initialDueDay,
    }),
    end_date: prepareDate({ date: endDate, due_day_of_month: initialDueDay }),
  });

  useEffect(() => {
    if (noEndDate !== noEndDateCalculated && !chargeInfo?.id) {
      setNoEndDate(noEndDateCalculated);
    }
  }, []);

  const isEndDateInThePast = () => {
    if (noEndDate) return false;

    const endDateMoment = moment(formData?.end_date, DATE_FORMATS_ARRAY);

    const startDateMoment = moment(formData?.start_date, DATE_FORMATS_ARRAY);

    const isEndDateBeforeToday = endDateMoment.isBefore(
      moment().startOf('day'),
    );

    return isEndDateBeforeToday || startDateMoment.isAfter(endDateMoment);
  };

  const isStartDatePast = moment(
    formData?.start_date,
    SQL_DATE_FORMAT,
  ).isBefore(moment().startOf('day'));

  const getSummaryDescription = () => {
    if (formData?.id && isStartDatePast) {
      let nextCharge;
      if (formData?.due_day_of_month < moment().date()) {
        nextCharge = moment()
          .add(1, 'month')
          .set('date', formData?.due_day_of_month);
      } else {
        nextCharge = moment().set('date', formData?.due_day_of_month);
      }
      return `The next charge will be due on ${nextCharge.format(
        'MMMM DD, YYYY',
      )}.`;
    }

    if (isStartDatePast && !formData?.id) {
      return (
        <span className={styles.errorText}>
          The first month they're charged cannot be in the past.
        </span>
      );
    }

    if (noEndDate) {
      return `The first monthly charge will be due on ${moment(
        formData?.start_date,
        DATE_FORMATS_ARRAY,
      ).format(
        CHARGES_EXTENDED_DATE_FORMAT_UI,
      )} and sent to your tenants every month until you delete this charge or the lease ends.`;
    }

    if (isEndDateInThePast()) {
      return (
        <span className={styles.errorText}>
          The last month they're charged must be after the first month.
        </span>
      );
    }

    return `The first monthly charge will be due on ${moment(
      formData?.start_date,
      DATE_FORMATS_ARRAY,
    ).format(
      CHARGES_EXTENDED_DATE_FORMAT_UI,
    )} and the last one will be due on ${moment(
      formData?.end_date,
      DATE_FORMATS_ARRAY,
    ).format(CHARGES_EXTENDED_DATE_FORMAT_UI)}.`;
  };

  const currentDatePlus10Years = new Date(new Date().getFullYear() + 10, 1, 1);

  const firstMonthMoment = moment(formData?.start_date, DATE_FORMATS_ARRAY);

  const lastMonthMoment = moment(formData?.end_date, DATE_FORMATS_ARRAY);

  const isEndInPast = isEndDateInThePast();

  const userBankOptions = bankAccountOptions.length
    ? [{ value: null, label: '' }, ...bankAccountOptions]
    : [];

  return (
    <Card className={styles.formCard}>
      <Form
        keepDirtyOnReinitialize
        onSubmit={async (data) => {
          await onSubmit({
            ...data,
            late_fee_policy: prepareLateFeeDataForSubmission(lateFeeData),
          });
        }}
        subscription={{
          submitting: true,
        }}
        initialValues={{
          ...formData,
          first_month: firstMonthMoment.month(),
          first_year: firstMonthMoment.year(),
          last_month: lastMonthMoment.month(),
          last_year: lastMonthMoment.year(),
          due_day_of_month: getInitialDueDay(formData),
          no_end_date: noEndDate,
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.categoryAmountRow}>
              <Field
                label="Category"
                component={SelectField}
                id="category"
                name="category"
                options={monthlyChargeCategoryOptions}
                className={styles.categorySelect}
                validate={fieldRequired}
                dataQa="monthly-charge-category-input"
              />
              <OnChange name="category">
                {(value) => {
                  setFormData({
                    ...formData,
                    category: value,
                    is_late_fee_percentage: false,
                    late_fee_amount: null,
                    late_fee_grace_period: 1,
                  });
                }}
              </OnChange>
              <Field
                component={InputWithIcon}
                id="amount"
                name="amount"
                className={styles.amountField}
                label="Amount"
                type="number"
                step=".01"
                pattern="[0-9]*"
                inputMode="decimal"
                validate={composeValidators(
                  isNumber,
                  fieldRequired,
                  isGreaterThan(0),
                )}
                icon={DollarIcon}
                inputProps={{
                  'data-qa': 'monthly-charge-amount-input',
                }}
              />
            </div>
            {!hideDescription && (
              <Field
                label="Description"
                component={Input}
                hint="(Optional)"
                name="description"
                id="description"
                className={styles.descriptionTextField}
                maxLength={50}
                help={(val = '') => `${val.length} / 50 characters used`}
              />
            )}
            <div className={styles.column}>
              <div className={styles.dueDateRow}>
                <OnChange name="due_day_of_month">
                  {(day) => {
                    setFormData({
                      ...formData,
                      due_day_of_month: day,
                      start_date: prepareDate({
                        date: formData?.start_date,
                        due_day_of_month: day,
                      }),
                      end_date: prepareDate({
                        date: formData?.end_date,
                        due_day_of_month: day,
                      }),
                    });
                  }}
                </OnChange>
                <Field
                  component={DueDateField}
                  id="due_day_of_month"
                  name="due_day_of_month"
                  label="Due Date"
                  className={styles.dueDateField}
                  validate={fieldRequiredShort}
                  format={(value) =>
                    value ? convertCardinalToOrdinal(value) : ''
                  }
                  dataQa="monthly-charge-due-day-of-month-input"
                />
              </div>
              {!isFromNewFlow && (
                <div className={cx(styles.description)}>
                  We automatically charge your tenants 15 days before the due
                  date each month.
                </div>
              )}
              <div className={cx(styles.monthsRow)}>
                <div className={cx(styles.monthsMobileContainer)}>
                  <OnChange name="first_month">
                    {(value) => {
                      setFormData({
                        ...formData,
                        start_date: prepareDate({
                          value: value,
                          date: formData?.start_date,
                          type: 'month',
                          due_day_of_month: formData?.due_day_of_month,
                        }),
                      });
                    }}
                  </OnChange>
                  <Field
                    component={SelectField}
                    disabled={isEdit && isStartDatePast}
                    id="first_month"
                    name="first_month"
                    label="First Month"
                    className={styles.monthInput}
                    options={monthNamesOptions}
                  />
                  <OnChange name="first_year">
                    {(value) => {
                      setFormData({
                        ...formData,
                        start_date: prepareDate({
                          value: value,
                          date: formData?.start_date,
                          type: 'year',
                          due_day_of_month: formData?.due_day_of_month,
                        }),
                      });
                    }}
                  </OnChange>
                  <Field
                    component={SelectField}
                    id="first_year"
                    disabled={isEdit && isStartDatePast}
                    name="first_year"
                    label="&nbsp;"
                    className={styles.yearInput}
                    options={yearListAsOptions(
                      chargeInfo?.start_date
                        ? new Date(chargeInfo.start_date)
                        : new Date(),
                      currentDatePlus10Years,
                    )}
                  />
                </div>
                {isEdit && isStartDatePast && (
                  <div className={cx(styles.errorText, styles.hideDesktop)}>
                    You can't edit it since it’s in the past.
                  </div>
                )}
                {!noEndDate && (
                  <>
                    <div className={styles.to}>TO</div>
                    <div className={cx(styles.monthsMobileContainer)}>
                      <OnChange name="last_month">
                        {(value) => {
                          setFormData({
                            ...formData,
                            end_date: prepareDate({
                              value: value,
                              date: formData?.end_date,
                              type: 'month',
                              due_day_of_month: formData?.due_day_of_month,
                            }),
                          });
                        }}
                      </OnChange>
                      <Field
                        component={SelectField}
                        id="last_month"
                        name="last_month"
                        label="Last Month"
                        className={styles.monthInput}
                        options={monthNamesOptions}
                        disabled={noEndDate}
                      />
                      <OnChange name="last_year">
                        {(value) => {
                          setFormData({
                            ...formData,
                            end_date: prepareDate({
                              value: value,
                              date: formData?.end_date,
                              type: 'year',
                              due_day_of_month: formData?.due_day_of_month,
                            }),
                          });
                        }}
                      </OnChange>
                      <Field
                        component={SelectField}
                        id="last_year"
                        name="last_year"
                        label="&nbsp;"
                        className={styles.yearInput}
                        options={yearListAsOptions(
                          new Date(),
                          currentDatePlus10Years,
                        )}
                        disabled={noEndDate}
                      />
                    </div>
                  </>
                )}
              </div>
              {isEdit && isStartDatePast && (
                <div className={cx(styles.errorText, styles.hideMobile)}>
                  You can’t edit it since it’s in the past.
                </div>
              )}
              <div className={styles.tooltipContainer}>
                <OnChange name="no_end_date">
                  {(value) => {
                    setNoEndDate(value);
                    setFormData({
                      ...formData,
                      no_end_date: noEndDate,
                    });
                  }}
                </OnChange>
                <Field
                  label="Create charges until the lease ends"
                  component={Checkbox}
                  type="checkbox"
                  id="no_end_date"
                  name="no_end_date"
                  className={styles.noEndDate}
                />
                <IconTooltip
                  tip={
                    <span>
                      Charges will automatically stop on the lease end date. If
                      the lease is set to continue month-to-month, charges will
                      be sent to your tenant until you delete the recurring
                      charge or end the lease.
                    </span>
                  }
                />
              </div>
            </div>
            <div className={styles.description}>{getSummaryDescription()}</div>
            {formData.category === EnumRentChargeType.RENT &&
              (lateFeeData.oneTimeFeeApplied || lateFeeData.dailyFeesApplied ? (
                <LateFeeDigest
                  data={lateFeeData}
                  onEdit={() => setIsLateFeesModalOpen(true)}
                />
              ) : (
                <Button
                  tertiary={true}
                  icon={PlusCircle}
                  className={styles.addLateFeesButton}
                  onClick={() => {
                    setIsLateFeesModalOpen(true);
                  }}
                >
                  Add Late Fees
                </Button>
              ))}
            <ConfigureLateFeesModal
              open={isLateFeesModalOpen}
              handleSubmit={(data) => {
                setLateFeeData(data);
                setIsLateFeesModalOpen(false);
              }}
              data={lateFeeData}
              onClose={() => setIsLateFeesModalOpen(false)}
            />
            {isPremiumUser && bankAccountOptions.length > 1 && (
              <Field
                label="Bank Account"
                component={SelectField}
                id="destination_id"
                name="destination_id"
                options={userBankOptions}
                className={styles.bankSelect}
                validate={fieldRequired}
              />
            )}
            <div className={styles.buttonRow}>
              {onCancel && (
                <Button
                  className={styles.button}
                  secondary
                  type="button"
                  disabled={submitting}
                  data-qa="monthly-charge-cancel-button"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              )}
              <Button
                className={styles.button}
                type="submit"
                loading={submitting}
                data-qa="monthly-charge-save-button"
                disabled={isEndInPast || (isStartDatePast && !isEdit)}
              >
                {!isEdit ? 'Add' : 'Save'}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </Card>
  );
};

MonthlyChargeForm.propTypes = {
  chargeInfo: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  isFromNewFlow: PropTypes.bool,
  isPremiumUser: PropTypes.bool,
  bankAccountOptions: PropTypes.array,
  lease: leasePropTypes,
  hideDescription: PropTypes.bool,
};

export default MonthlyChargeForm;
