import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import get from 'lodash.get';
import isBoolean from 'lodash/isBoolean';
import moment from 'moment';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import { Experiments } from '../../../constants/experiments';
import userReiHubPricing from '../../../core/hooks/userReiHubPricing';
import { useUserFields } from '../../../core/TTgraphql';
import createReiHubSubscription from '../../../graphql/mutations/rei_hub/createReiHubSubscription.graphql';
import ownerSettingsQueryGQL from '../../../graphql/queries/ownerSettingsQuery.graphql';
import calculateReiHubPrice from '../../../helpers/calculate-reihub-price';
import { createAndSaveIdempotencyKey } from '../../../helpers/createAndSaveIdempotencyKey';
import getExperimentVariant from '../../../helpers/experiments';
import getReiHubPrice from '../../../helpers/getReiHubPrice';
import { resetIdempotencyKey } from '../../../helpers/resetIdempotencyKey';
import DecorativeLines from '../../../icons/DecorativeLines';
import RadialCirclePattern from '../../../icons/RadialCircle';
import Accounting from '../../../icons/streamline/Accounting';
import Close from '../../../icons/streamline/Close';
import PreScreener from '../../../icons/streamline/PreScreener';
import Unlock from '../../../icons/streamline/Unlock';
import StripeElementsComponent from '../../../pages/common/stripe/StripeElementsComponent';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';
import IconButton from '../../IconButton';
import Modal from '../../Modal';
import { useErrorToast } from '../../Toast';
import { H4, Paragraph } from '../../Typography';
import VerticalListWithIcons from '../../VerticalListWithIcons';
import {
  FREE_TRIAL_DAYS,
  REIHUB_50_1Y_COUPON_CODE,
  REIHUB_DEFAULT_COUPON_CODE,
  REIHUB_DISCOUNT_FACTOR,
  RENEWAL_FREQUENCIES,
} from '../constants';

import googleReviewImage from './assets/google-review.svg';
import roxyReviewerImage from './assets/roxy-reviewer.png';

import styles from './ReiHubModalWrapped.module.scss';

const AMOUNT_LABELS = {
  [RENEWAL_FREQUENCIES.MONTHLY]: 'PER MONTH',
  [RENEWAL_FREQUENCIES.QUARTERLY]: 'PER QUARTER',
  [RENEWAL_FREQUENCIES.YEARLY]: 'PER YEAR',
};

const AUTO_RENEWAL_PERIODS = {
  [RENEWAL_FREQUENCIES.MONTHLY]: 'monthly',
  [RENEWAL_FREQUENCIES.QUARTERLY]: (
    <>
      every
      <br />3 months
    </>
  ),
  [RENEWAL_FREQUENCIES.YEARLY]: 'yearly',
};

const howFreeTrialWorks = [
  {
    icon: Unlock,
    iconProps: { width: 24, height: 24, color: colors.primary },
    content: (
      <div className={styles.howFreeTrialWorksItem}>
        <Paragraph weight="bold">Today</Paragraph>
        <Paragraph>
          Unlock access to automated rental accounting for $0.
        </Paragraph>
      </div>
    ),
  },
  {
    icon: Accounting,
    iconProps: { width: 24, height: 24, color: colors.lightGray },
    content: (
      <div className={styles.howFreeTrialWorksItem}>
        <Paragraph weight="bold">Day 1-14</Paragraph>
        <Paragraph>Use all REI Hub features for free.</Paragraph>
      </div>
    ),
  },
  {
    icon: PreScreener,
    iconProps: { width: 24, height: 24, color: colors.lightGray },
    content: (
      <div className={styles.howFreeTrialWorksItem}>
        <Paragraph weight="bold">Day 15</Paragraph>
        <Paragraph>
          Your subscription starts! You'll be charged $15/month. You can easily{' '}
          <strong>cancel anytime</strong>.
        </Paragraph>
      </div>
    ),
  },
];

const ReiHubModalWrapped = ({
  onClose,
  onSuccess,
  refetchQueries,
  unitCount,
  renewalFrequency,
  disableFreeTrial,
  isPromotional,
  forceTrial,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [isFreeTrialVersion, setIsFreeTrialVersion] = useState(!!forceTrial);

  const { user } = useUserFields([
    'reihub_listings_count',
    'active_experiments',
  ]);

  const {
    REI_HUB_PLAN_ID,
    REI_HUB_ANNUAL_PLAN_ID,
    REI_HUB_ANNUAL_PRICE_FULL_PLAN_ID,
  } = useConfig();

  const errorToast = useErrorToast();

  const pricingMap = userReiHubPricing();

  const { reihub_listings_count: listings_count, id: userId } = user;

  const { loading: loadingOwnerSettings, data: ownerSettingsData } = useQuery(
    ownerSettingsQueryGQL,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const trialActivated =
    ownerSettingsData?.userData?.settingsAndBilling?.data
      ?.reiHubSubscriptionInfo?.is_reihub_trial_activated;

  useEffect(() => {
    if (
      !loadingOwnerSettings &&
      ownerSettingsData &&
      isBoolean(trialActivated) &&
      !disableFreeTrial &&
      !forceTrial
    ) {
      setIsFreeTrialVersion(!trialActivated);
    }
  }, [
    loadingOwnerSettings,
    trialActivated,
    ownerSettingsData,
    disableFreeTrial,
    forceTrial,
  ]);

  const title =
    renewalFrequency === RENEWAL_FREQUENCIES.QUARTERLY && !isFreeTrialVersion
      ? 'Rental Accounting Subscription'
      : 'Subscribe to REI Hub';

  const pricing =
    unitCount !== null && renewalFrequency !== null
      ? getReiHubPrice(pricingMap, unitCount)
      : null;

  const monthlyPrice = calculateReiHubPrice(listings_count);
  const monthlyReiHubPrice = monthlyPrice * REIHUB_DISCOUNT_FACTOR;

  const [createSubscription] = useMutation(createReiHubSubscription, {
    refetchQueries: refetchQueries?.length
      ? refetchQueries.map((query) => ({ query }))
      : null,
    awaitRefetchQueries: true,
  });

  const planMapping = {
    freeTrial: {
      plan_id: REI_HUB_PLAN_ID,
      trial_period_days: FREE_TRIAL_DAYS,
    },
    annualFull: {
      plan_id: REI_HUB_ANNUAL_PRICE_FULL_PLAN_ID,
      unitCount,
      coupon: REIHUB_50_1Y_COUPON_CODE,
    },
    annual: {
      plan_id: REI_HUB_ANNUAL_PLAN_ID,
      unitCount,
    },
    default: {
      plan_id: REI_HUB_PLAN_ID,
      coupon: REIHUB_DEFAULT_COUPON_CODE,
    },
  };

  const getPlanDataForSubscription = () => {
    if (isFreeTrialVersion) return planMapping.freeTrial;
    if (isPromotional) return planMapping.annualFull;
    if (unitCount) return planMapping.annual;
    return planMapping.default;
  };

  const planData = getPlanDataForSubscription();

  const onToken = useCallback(async (token) => {
    let error;
    try {
      const idempotencyKey = createAndSaveIdempotencyKey();
      await createSubscription({
        variables: {
          token,
          idempotencyKey,
          ...planData,
        },
      });

      if (disableFreeTrial) {
        history.replace({ search: '' });
      }

      onSuccess();
    } catch (e) {
      const message = get(e, 'graphQLErrors[0].message', 'An error occurred');
      errorToast(message);
      error = e?.graphQLErrors;
    }

    resetIdempotencyKey(error);
  });

  const reiHubAccountingPageVariant = getExperimentVariant(
    user,
    Experiments.FreeTrialCreditCardModal.name,
  );

  const isCreditCardModalV1 =
    reiHubAccountingPageVariant ===
    Experiments.FreeTrialCreditCardModal.variants.variant_1;

  let price = monthlyReiHubPrice;
  let slashedPrice = null;

  if (pricing !== null) {
    if (renewalFrequency === RENEWAL_FREQUENCIES.QUARTERLY) {
      price = pricing.quarterly_price;
      slashedPrice = pricing.slashedPrice * 3;
    } else if (renewalFrequency === RENEWAL_FREQUENCIES.YEARLY) {
      price = isPromotional ? pricing.slashedPrice / 2 : pricing.price;
      slashedPrice = pricing.slashedPrice;
    } else {
      price = monthlyReiHubPrice;
    }
  }

  const trialOfferPrice = pricing?.slashedPrice ?? monthlyPrice;

  const trialEndDate = moment().add(FREE_TRIAL_DAYS, 'days');
  const formattedDate = trialEndDate.format('MM/DD/YY');

  const autoRenewTermsFreeTrialOffer = (
    <span className={styles.terms}>
      I understand I'll be charged ${trialOfferPrice}/mo starting on{' '}
      {formattedDate}, unless I cancel. Price subject to change based on units.
    </span>
  );

  const handlePayClicked = () => {
    if (isFreeTrialVersion) {
      segmentTracking(`subscribe clicked`, {
        location: 'REI Hub Free Trial Credit Card Form',
      });
    } else {
      segmentTracking(`purchase_reihub_subscription clicked`, {
        location: 'reihub accounting tab subscription modal',
      });
    }
  };

  const removeUpdateCardParams = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('change-card');
    const newSearchString = searchParams.toString();
    history.replace({
      ...location,
      search: newSearchString,
    });
  };

  if (!userId) {
    return null;
  }

  return (
    <Modal
      open={!loadingOwnerSettings}
      className={cx(styles.container, {
        [styles.creditCardModalV1]: isCreditCardModalV1,
      })}
      mobileFullScreen
    >
      <div
        className={cx(styles.reiHubModalWrapped, {
          [styles.reiHubModalWrappedActivated]: isCreditCardModalV1,
        })}
      >
        {isCreditCardModalV1 && (
          <div className={styles.reiHubModalWrappedContent}>
            <RadialCirclePattern
              className={styles.radialCirclePattern}
              color={colors.babyBlue}
            />
            <DecorativeLines className={styles.decorativeLines} />
            <div>
              <H4>How your free trial works:</H4>
              <VerticalListWithIcons
                items={howFreeTrialWorks}
                className={styles.verticalList}
                iconWrapperClassName={styles.verticalListIconWrapper}
                itemClassName={styles.verticalListItem}
              />
            </div>
            <div className={styles.devider} />
            <div className={styles.reviewContainer}>
              <img src={roxyReviewerImage} alt="Roxy Reviewer" />
              <div>
                <Paragraph>
                  REI Hub is great! The software has been so easy to use and{' '}
                  <strong>far better than just using a spreadsheet</strong>.
                </Paragraph>
                <span>— Roxy, Long Term Rental Investor</span>
              </div>
            </div>
            <img
              src={googleReviewImage}
              alt="Google Review"
              className={styles.googleReviewImage}
            />
          </div>
        )}
        <div className={styles.stripeElementsWrapper}>
          <IconButton
            onClick={() => {
              removeUpdateCardParams();
              onClose();
            }}
            icon={Close}
            className={styles.closeAction}
            iconProps={{
              width: 24,
              height: 24,
              color: colors.darkGray,
              strokeWidth: 1,
            }}
          />
          <StripeElementsComponent
            className={styles.stripeModalContainer}
            autoRenewTermsLink="https://www.turbotenant.com/terms-of-use/#partnerships"
            onPayClicked={handlePayClicked}
            onToken={onToken}
            title={title}
            {...(isFreeTrialVersion
              ? {
                  amount: trialOfferPrice,
                  amountToShow: 0.0,
                  amountTitle: 'Total Due Today',
                  disclaimer: autoRenewTermsFreeTrialOffer,
                  buttonLabel: isCreditCardModalV1
                    ? 'START FREE TRIAL'
                    : 'SUBSCRIBE',
                }
              : {
                  amount: price,
                  slashedAmount: slashedPrice,
                  buttonLabel: pricing !== null ? `Pay $${price}` : 'SUBSCRIBE',
                  autoRenewPeriod:
                    AUTO_RENEWAL_PERIODS[renewalFrequency] ??
                    // NOTICE: this line of code below is here for backwards compatibility
                    (pricing ? 'yearly' : 'monthly'),
                  amountLabel:
                    AMOUNT_LABELS[renewalFrequency] ??
                    // NOTICE: this line of code below is here for backwards compatibility
                    (pricing ? 'year' : 'month'),
                })}
          />
        </div>
      </div>
    </Modal>
  );
};

ReiHubModalWrapped.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  refetchQueries: PropTypes.array,
  unitCount: PropTypes.number,
  renewalFrequency: PropTypes.oneOf(Object.values(RENEWAL_FREQUENCIES)),
  disableFreeTrial: PropTypes.bool,
  isPromotional: PropTypes.bool,
  forceTrial: PropTypes.bool,
};

export default ReiHubModalWrapped;
