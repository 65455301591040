import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import { useUserProfile } from '../../core/TTgraphql';
import basicUserProfile from '../../graphql/basicUserProfile.graphql';
import updateOwnerSubscriptionPlanGQL from '../../graphql/mutations/updateOwnerSubscriptionPlan.graphql';
import ownerSettingsQuery from '../../graphql/queries/ownerSettingsQuery.graphql';
import { useRenderOutside } from '../../helpers/render-outside';
import Applicant from '../../icons/ApplicantThick';
import PremiumBadge from '../../icons/PremiumBadge';
import RadioCheck from '../../icons/RadioCheck';
import { useConfig } from '../../providers/ConfigProvider';
import {
  getItem,
  removeItem,
  setItem,
} from '../../services/localStorageService';
import { segmentTracking } from '../../services/utilities';
import PremiumSubscriptionModal from '../PremiumSubscription';
import { SUBSCRIPTION_FEATURES } from '../PremiumSubscription/constants';
import getSegmentLocation from '../PremiumSubscription/get-premium-modal-segment-location';
import UpgradeToPremium from '../PremiumSubscription/UpgradeToPremium';
import SectionTitleWithIcon from '../SectionTitleWithIcon';
import SuccessModal from '../SuccessModal';
import { useErrorToast } from '../Toast';

import AreYouSureModal from './AreYouSureModal';
import PremiumRadioButtonOffer from './PremiumRadioButtonOffer';

import styles from './PremiumRadioButtonOfferWrapped.module.scss';

const ARE_YOU_SURE_KEY = 'are_you_sure_modal_pop_count';
const ARE_YOU_SURE_DATE_KEY = 'are_you_sure_modal_date_set';

const PremiumRadioButtonOfferWrapped = ({
  input,
  label,
  className,
  openLocation,
  purchase_location,
  hideLabel = false,
  premium,
  ...props
}) => {
  const { user } = useUserProfile({}, false);
  const renderOutside = useRenderOutside();
  const showErrorMessage = useErrorToast();
  const { DEFAULT_APPLICATION_FEE, PREMIUM_PLAN_ID_DEFAULT } = useConfig();
  const { data: ownerSettingsData } = useQuery(ownerSettingsQuery);
  const [showUpgradeToPremium, setShowUpgradeToPremium] = useState(false);

  const premiumSelected = input.value === 'premium';
  const premiumPriceSignup = user?.premium_price_signup;
  const hasSubscription = user?.packageFeatures?.length > 0;
  const premiumSubscribed = user?.premium_subscription_subscribed;

  const { premiumPlusSubscriptionInfo } =
    ownerSettingsData?.userData?.settingsAndBilling?.data || {};

  const activeSubscriptionEnd =
    premiumPlusSubscriptionInfo?.current?.current_period_end;

  const isIncomeInsightsEnabled = user?.packageFeatures?.includes(
    SUBSCRIPTION_FEATURES.INCOME_INSIGHTS,
  );

  const areYouSureModalCount = Number(getItem(ARE_YOU_SURE_KEY)) || 0;
  const areYouSureModalDate = getItem(ARE_YOU_SURE_DATE_KEY) || null;

  const [showAreYouSure, setShowAreYouSure] = useState();
  const [showPremium, setShowPremium] = useState(false);

  const applicationFee =
    get(user, 'application_fee', '') || DEFAULT_APPLICATION_FEE;
  const appFeePriceExpPremiumPrice = get(
    user,
    'app_fee_price_exp_premium_price',
  );

  useEffect(() => {
    if (areYouSureModalDate && Date.now() > areYouSureModalDate) {
      setItem(ARE_YOU_SURE_KEY, 0);
      setShowAreYouSure(true);
      removeItem(ARE_YOU_SURE_DATE_KEY);
    }

    if (showAreYouSure === undefined && user.id) {
      setShowAreYouSure(areYouSureModalCount < 3);
    }
  }, [user?.id]);

  useEffect(() => {
    if (premiumSubscribed && isIncomeInsightsEnabled) {
      input.onChange('premium');
    } else {
      input.onChange(null);
    }
  }, [premiumSubscribed, isIncomeInsightsEnabled]);

  useEffect(() => {
    setShowUpgradeToPremium(
      premiumSelected && hasSubscription && !isIncomeInsightsEnabled,
    );
  }, [premiumSelected, hasSubscription, isIncomeInsightsEnabled]);

  const [updateOwnerSubscriptionPlanMutation] = useMutation(
    updateOwnerSubscriptionPlanGQL,
  );

  const upgradeToPremium = async () => {
    try {
      await updateOwnerSubscriptionPlanMutation({
        variables: {
          newPlanId: PREMIUM_PLAN_ID_DEFAULT,
          referral: 'Upgrade Modal',
          action: 'Upgrade',
        },
      });

      setShowUpgradeToPremium(false);

      renderOutside((done) => (
        <SuccessModal
          open
          showCheckMark
          showAnimation={false}
          title="Upgrade complete!"
          primaryLabel="Sounds Good"
          primaryAction={() => {
            segmentTracking('sounds_good clicked', {
              location: 'Plan Upgraded Success Modal',
            });
            done();
          }}
          className={styles.upgradeModal}
          classNameTitle={styles.title}
          classNameActions={styles.actions}
        >
          <p className={styles.text}>
            Enjoy access to all the features that come with your new plan.
          </p>
        </SuccessModal>
      ));
    } catch {
      showErrorMessage('Failed to upgrade to premium');
    }
  };

  return (
    <div className={className}>
      {!hideLabel && (
        <SectionTitleWithIcon
          centered={false}
          title={label}
          icon={Applicant}
          iconClassNames={styles.icon}
        />
      )}

      {isIncomeInsightsEnabled ? (
        <div className={styles.hasPremium}>
          <PremiumBadge className={styles.premiumBadge} />
          <div className={styles.iconHolder}>
            <RadioCheck className={styles.radioCheck} />
          </div>
          <div className={styles.descData}>
            <span className={styles.offerButtonTitle}>PREMIUM UNLOCKED</span>
            <span className={styles.offerButtonDesc}>{premium?.desc}</span>
            <span className={styles.offerButtonDesc2}>{premium?.desc2}</span>
          </div>
        </div>
      ) : (
        <>
          <PremiumRadioButtonOffer
            {...props}
            input={input}
            premium={{
              ...premium,
              tracking: {
                ...premium.tracking,
                event: hasSubscription
                  ? 'apply_now_premium_upgrade clicked'
                  : premium.tracking.event,
              },
            }}
          />

          <AreYouSureModal
            onClose={() => {
              segmentTracking('not_right_now clicked', {
                location: getSegmentLocation(openLocation),
              });
              setItem(ARE_YOU_SURE_KEY, areYouSureModalCount + 1);
              if (areYouSureModalCount > 2 && !areYouSureModalDate) {
                // we are setting the date to 6 months from now
                setItem(
                  ARE_YOU_SURE_DATE_KEY,
                  Date.now() + 6 * 30 * 24 * 60 * 60 * 1000,
                );
              }
              setShowAreYouSure(false);
            }}
            showPremium={() => {
              segmentTracking('learn_more_about_fee clicked', {
                location: getSegmentLocation(openLocation),
              });

              setShowAreYouSure(false);
              hasSubscription
                ? setShowUpgradeToPremium(true)
                : setShowPremium(true);
            }}
            onLoad={() =>
              segmentTracking('are_you_sure_modal loaded', {
                location: getSegmentLocation(openLocation),
              })
            }
            yourPrice={applicationFee}
            discountPrice={appFeePriceExpPremiumPrice}
            open={input.value === 'free' && showAreYouSure}
          />

          {premiumSelected && !hasSubscription && (
            <PremiumSubscriptionModal
              openLocation={openLocation}
              copy="inviteToApply"
              onClose={() => input.onChange(null)}
              onSuccess={() => input.onChange(null)}
              refetchQueries={[basicUserProfile, ownerSettingsQuery]}
              purchase_location={purchase_location}
            />
          )}

          {showUpgradeToPremium && (
            <UpgradeToPremium
              open
              onClose={() => {
                input.onChange(null);
                setShowUpgradeToPremium(false);
              }}
              onUpgrade={upgradeToPremium}
              premiumPrice={premiumPriceSignup}
              subscriptionEnd={activeSubscriptionEnd}
            />
          )}

          {showPremium && (
            <PremiumSubscriptionModal
              openLocation={openLocation}
              copy="inviteToApply"
              onClose={() => setShowPremium(false)}
              refetchQueries={[basicUserProfile, ownerSettingsQuery]}
              onSuccess={() => setShowPremium(false)}
              purchase_location={purchase_location}
            />
          )}
        </>
      )}
    </div>
  );
};

PremiumRadioButtonOfferWrapped.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  openLocation: PropTypes.string,
  purchase_location: PropTypes.string,
  hideLabel: PropTypes.bool,
  premium: PropTypes.object,
};

export default PremiumRadioButtonOfferWrapped;
