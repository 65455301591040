import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import { Experiments } from '../../constants/experiments';
import {
  getCurrentToken,
  isUserProfileToken,
} from '../../core/auth/authService';
import { useUserProfile } from '../../core/TTgraphql';
import { useStatusEndpoint } from '../../helpers/status-endpoint';
import { useConfig } from '../ConfigProvider';

import { ActiveExperimentsContext } from './context';

const ActiveExperimentsProvider = ({ children }) => {
  const { setConfig } = useConfig();
  const location = useLocation();

  // We want to recompute shouldFetchProfile when the location changes, otherwise going from logged out to logged in will not trigger a re-fetch of the user profile
  const shouldFetchProfile = useMemo(() => {
    return isUserProfileToken(getCurrentToken());
  }, [location]);

  const { user, loadingUser } = useUserProfile({
    skip: !shouldFetchProfile,
  });

  const { cookieExperiments } = useStatusEndpoint();
  const experiments = useMemo(() => {
    const userExperiments =
      user?.active_experiments?.reduce((acc, experiment) => {
        acc[experiment.name] = experiment.value;
        return acc;
      }, {}) || null;

    const ownerExperiments =
      user?.owner_active_experiments?.reduce((acc, experiment) => {
        acc[experiment.name] = experiment.value;
        return acc;
      }, {}) || null;

    return {
      userExperiments,
      ownerExperiments,
      cookieExperiments,
    };
  }, [user, cookieExperiments]);

  useEffect(() => {
    if (experiments.userExperiments && !loadingUser) {
      const gtAlcLeaseVariant = experiments?.userExperiments?.gt_alc_lease;
      const leaseAgreementFees = {
        [Experiments.GtAlcLease.variants.variant_1]: 79,
        [Experiments.GtAlcLease.variants.variant_2]: 99,
      };
      const fee = leaseAgreementFees?.[gtAlcLeaseVariant];
      if (fee) {
        setConfig((prevConfig) => ({
          ...prevConfig,
          LEASE_AGREEMENT_FEE: fee,
        }));
      }
    }
  }, [experiments.userExperiments, setConfig, loadingUser]);

  const isLoggedIn = user !== null;

  if (
    loadingUser ||
    (!experiments.userExperiments && isLoggedIn) || // We only wait for userExperiments if user is logged in
    !experiments.cookieExperiments // If user is not logged in, we still need to wait for cookieExperiments
  ) {
    return <LoadingScreen loading />;
  }

  return (
    <ActiveExperimentsContext.Provider value={experiments}>
      {children}
    </ActiveExperimentsContext.Provider>
  );
};

ActiveExperimentsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ActiveExperimentsProvider;
