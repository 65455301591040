import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

import LoadingScreen from '../../../components/LoadingScreen';
import OptionItemCard from '../../../components/OptionItemCard';
import PartnersCard from '../../../components/PartnersCard';
import PremiumToolbox from '../../../components/PremiumSubscription/PremiumToolbox';
import ReiHubLoadingModal, {
  useOpenREIHub,
} from '../../../components/ReiHubSubscription/ReiHubLoadingModal';
import { FEATURE_FLAGS } from '../../../constants/feature_flags';
import WAITLIST_FEATURES from '../../../constants/owner_waitlist_features/waitlist-features';
import { useUserProfile } from '../../../core/TTgraphql';
import toolboxWaitlistFeaturesGQL from '../../../graphql/queries/toolbox/toolboxWaitlistFeatures.graphql';
import useArmadilloLink from '../../../helpers/useArmadilloLink';
import useAddFeatureToWaitlist from '../../../hooks/useAddFeatureToWaitlist';
import ArmadiloLogo from '../../../icons/ArmadiloLogo';
import CalendarColored from '../../../icons/CalendarColored';
import DollarDocumentColor from '../../../icons/DollarDocumentColor';
import EzEvictUSALogo from '../../../icons/EzEvictUSA';
import LeaseAI from '../../../icons/LeaseAI';
import LegalZoomLogo from '../../../icons/LegalZoomLogo';
import LoadUpLogo from '../../../icons/LoadUpLogo';
import LulaLogo from '../../../icons/Lula';
import NewWesternLogo from '../../../icons/NewWesternLogo';
import NewWindow from '../../../icons/NewWindow';
import OwnwellLogo from '../../../icons/OwnwellLogo';
import PieChart from '../../../icons/PieChartColor';
import PurchaseHouse from '../../../icons/PurchaseHouse';
import ReiHubLogo from '../../../icons/ReiHubLogo';
import Subscription from '../../../icons/Subscription';
import TurboTaxLogo from '../../../icons/TurboTaxLogo';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';

import SteadilyToolboxCard from './SteadilyToolboxCard';
import HeaderImage from './ToolboxHeaderImage';

import styles from './ToolboxPage.module.scss';

const ToolboxPage = () => {
  const { user, features = [], loading } = useUserProfile({}, false);
  const hasReiHub = user?.reihub_subscription_subscribed;
  const [isModalOpen, setOpenREIHub] = useOpenREIHub({
    isDemoMode: !user.reihub_subscription_subscribed,
  });
  const ownwellEllgible = user?.ownwell_ellgible;
  const { data } = useQuery(toolboxWaitlistFeaturesGQL);
  const waitlistFeatures = data?.toolbox_waitlist_features?.features || [];
  const { PRIVATE_BASE_PATH, EZ_EVICTION_USA_URL, LEGAL_ZOOM_URL } =
    useConfig();
  const isLulaEnabled = features?.includes(FEATURE_FLAGS.LULA_MAINTENANCE);
  const isLulaEligible = user?.lula_eligible && isLulaEnabled;
  const hasMaintenancePlusSubscribed =
    user.maintenance_plus_subscription_subscribed;

  const isLulaExperimentActive = get(
    user,
    'maintenance_plus_active_experiment',
    false,
  );
  const maintenancePlusSettings =
    user?.maintenance_request_management_settings || false;
  const isEnrolledFreeLula =
    isLulaExperimentActive && !!maintenancePlusSettings;

  const addFeatureToWaitlist = useAddFeatureToWaitlist();

  const SteadilyCard = useCallback(
    () => <SteadilyToolboxCard user={user} />,
    [user],
  );

  const REIHubCard = useCallback(
    () => (
      <PartnersCard
        Logo={ReiHubLogo}
        title="Accounting, just for landlords"
        description="Built for real estate investors, REI Hub makes it easy to manage your accounting. Link your TurboTenant rental and payments to supercharge your accounting process."
        tagProps={{
          label: 'Accounting',
        }}
        actions={
          hasReiHub
            ? [
                {
                  label: 'Go to REI HUB',
                  onClick: () => {
                    setOpenREIHub();
                    setTimeout(() => document.activeElement.blur(), 1);
                    segmentTracking('go_to_reihub clicked', {
                      location: 'toolbox',
                    });
                  },
                  icon: NewWindow,
                  iconAfter: true,
                  iconProps: {
                    color: '#FFF',
                    width: 11.25,
                    height: 11.25,
                  },
                },
              ]
            : [
                {
                  label: 'Try demo account',
                  secondary: true,
                  onClick: () => {
                    setOpenREIHub();
                    setTimeout(() => document.activeElement.blur(), 1);
                    segmentTracking('try_reihub_demo_account clicked', {
                      location: 'toolbox',
                    });
                  },
                },
                {
                  label: 'Get accounting',
                  to: '/owners/payments/accounting',
                  onClick: () => {
                    segmentTracking('get_accounting clicked', {
                      location: 'toolbox',
                    });
                  },
                },
              ]
        }
      />
    ),
    [hasReiHub],
  );

  const EzEvictUSA = () => (
    <PartnersCard
      Logo={EzEvictUSALogo}
      title="Take the hassle out of evictions."
      description="Let EZEvictUSA take care of the tedious eviction process so you can save money and time."
      tagProps={{
        label: 'Business',
      }}
      logoClassName={styles.ezEvictLogo}
      newTag
      actions={[
        {
          label: 'Start Eviction',
          onClick: () => {
            window.open(EZ_EVICTION_USA_URL, '_blank');
            segmentTracking('start_eviction clicked', {
              location: 'toolbox',
            });
            setTimeout(() => document.activeElement.blur(), 1);
          },
        },
      ]}
    />
  );

  const LulaMaintenanceCard = useCallback(() => {
    const isSubscribed = hasMaintenancePlusSubscribed || isEnrolledFreeLula;

    const getButtonLabel = () => {
      if (isLulaEligible) {
        return isSubscribed ? 'Subscribed' : 'Learn More';
      }
      return 'Join waitlist';
    };

    const handleClick = () => {
      if (isLulaEligible) {
        if (!isSubscribed) {
          window.open(`${PRIVATE_BASE_PATH}maintenance/lula`, '_blank');
        }
      } else {
        addFeatureToWaitlist(WAITLIST_FEATURES.LULA_MAINTENANCE);
      }
    };

    return (
      <PartnersCard
        title="Hire an in-house maintenance team"
        description="Lula will handle inbound requests, troubleshoot with your tenant, and send a pro out (if needed)!"
        Logo={LulaLogo}
        tagProps={{
          label: 'Maintenance',
        }}
        actions={[
          {
            disabled: hasMaintenancePlusSubscribed || isEnrolledFreeLula,
            secondary: true,
            label: getButtonLabel(),
            onClick: handleClick,
          },
        ]}
        addedToWaitlist={
          waitlistFeatures.includes(WAITLIST_FEATURES.LULA_MAINTENANCE) &&
          !hasMaintenancePlusSubscribed &&
          !isEnrolledFreeLula &&
          !isLulaEligible
        }
      />
    );
  });

  const armadiloLink = useArmadilloLink();

  const ArmadiloCard = useCallback(() => (
    <PartnersCard
      title="Protect your property and your ROI"
      description={
        <>
          Let Armadillo take care of all your rental repairs & replacements
          while covering the bills!
          <br />
          Start your 30-day free trial now.
        </>
      }
      tagProps={{
        label: 'insurance',
      }}
      Logo={ArmadiloLogo}
      actions={[
        {
          label: 'Get Covered',
          target: '_blank',
          href: armadiloLink,
          onClick: () => {
            segmentTracking('get_covered clicked', {
              location: 'toolbox',
            });
            setTimeout(() => document.activeElement.blur(), 1);
          },
        },
      ]}
      addedToWaitlist={waitlistFeatures.includes(WAITLIST_FEATURES.ARMADILLO)}
    />
  ));

  const OwnwellCard = useCallback(() => (
    <PartnersCard
      title="Save money on your property taxes"
      description="Millions of homeowners overpay their property taxes every year. Let Ownwell's local property tax experts file an appeal on your behalf with no up-front costs."
      tagProps={{
        label: 'taxes',
      }}
      Logo={OwnwellLogo}
      newTag
      actions={
        ownwellEllgible
          ? [
              {
                label: 'Check Eligibility',
                secondary: true,
                onClick: () => {
                  segmentTracking('ownell_save_today clicked', {
                    location: 'toolbox',
                  });
                  window.open('https://www.ownwell.com/turbotenant', '_blank');
                  setTimeout(() => document.activeElement.blur(), 1);
                },
              },
            ]
          : [
              {
                label: 'Join Waitlist',
                secondary: true,
                onClick: () =>
                  addFeatureToWaitlist(WAITLIST_FEATURES.OWNWELL, 'toolbox'),
              },
            ]
      }
      addedToWaitlist={waitlistFeatures.includes(WAITLIST_FEATURES.OWNWELL)}
    />
  ));

  const LoadUpCard = useCallback(() => (
    <PartnersCard
      Logo={LoadUpLogo}
      title="Convenient junk removal"
      description="LoadUp offers the easiest and most affordable junk removal services. From large bulky furniture to a full clean-out – they'll handle all the heavy lifting. Get an upfront price today."
      tagProps={{
        label: 'Business',
      }}
      actions={[
        {
          label: 'Get my price',
          onClick: () => {
            window.open(
              'https://goloadup.com/affiliate/turbo-tenant/',
              '_blank',
            );
            setTimeout(() => document.activeElement.blur(), 1);
          },
        },
      ]}
      addedToWaitlist={waitlistFeatures.includes(WAITLIST_FEATURES.NOVO)}
    />
  ));

  const LegalZoomCard = useCallback(() => (
    <PartnersCard
      Logo={LegalZoomLogo}
      title="Register your LLC for free"
      description="Get fast, simple, and reliable services to launch your LLC with LegalZoom. Starts at $0 + filing fees"
      tagProps={{
        label: 'Business',
      }}
      actions={[
        {
          label: 'Register my LLC',
          onClick: () => {
            window.open(LEGAL_ZOOM_URL, '_blank');
            setTimeout(() => document.activeElement.blur(), 1);
          },
        },
      ]}
      addedToWaitlist={waitlistFeatures.includes(WAITLIST_FEATURES.NOVO)}
    />
  ));

  const NewWesternCard = useCallback(() => (
    <PartnersCard
      Logo={NewWesternLogo}
      title="Grow your rental portfolio"
      description="Work with their local REI agents to access the largest source of off-market investment properties in the nation."
      tagProps={{
        label: 'Business',
      }}
      actions={[
        {
          label: 'View properties',
          onClick: () => {
            window.open(
              'https://www.newwestern.com/invest-with-us?utm_source=tt&utm_medium=partner&utm_campaign=toolbox',
              '_blank',
            );
            setTimeout(() => document.activeElement.blur(), 1);
          },
        },
      ]}
      addedToWaitlist={waitlistFeatures.includes(WAITLIST_FEATURES.NOVO)}
    />
  ));

  const TurboTaxCard = useCallback(() => (
    <PartnersCard
      title="Simplify your tax filing with TurboTax"
      description="Take the stress out of tax season. TurboTax accurately imports your 1099 forms—so you can spend more time managing your rental properties."
      tagProps={{
        label: 'taxes',
      }}
      logoClassName={styles.turboTaxLogo}
      Logo={TurboTaxLogo}
      newTag
      actions={[
        {
          label: 'Start Your Taxes',
          onClick: () => {
            window.open(
              'https://www.dpbolvw.net/click-101114470-16985096?sid=toolbox',
              '_blank',
            );
            setTimeout(() => document.activeElement.blur(), 1);
          },
        },
      ]}
    />
  ));

  const renderPartnersSection = useCallback(
    () => (
      <div className={styles.partnersInnerContainer}>
        <div className={styles.hideMobile}>
          {REIHubCard()}
          {OwnwellCard()}
          {SteadilyCard()}
          {LoadUpCard()}
          {ArmadiloCard()}
        </div>
        <div className={styles.hideMobile}>
          {TurboTaxCard()}
          {LegalZoomCard()}
          {NewWesternCard()}
          {EzEvictUSA()}
          {LulaMaintenanceCard()}
        </div>
        <div className={styles.hideDesktop}>
          {REIHubCard()}
          {TurboTaxCard()}
          {OwnwellCard()}
          {LegalZoomCard()}
          {SteadilyCard()}
          {NewWesternCard()}
          {LoadUpCard()}
          {EzEvictUSA()}
          {ArmadiloCard()}
          {LulaMaintenanceCard()}
        </div>
      </div>
    ),
    [waitlistFeatures, user],
  );

  return (
    <LoadingScreen className={styles.toolboxContainer} loading={loading}>
      <div className={styles.header}>
        <div className={styles.headerText}>
          <HeaderImage className={styles.headerImage} />
          <h2>
            Landlord <span>Toolbox</span>
          </h2>
          <p>Resources to help you landlord like a pro.</p>
        </div>
      </div>
      <div className={styles.partnersContainer}>
        <h3>Partners</h3>
        <p>Our network of trusted partners curated just for you.</p>
        {renderPartnersSection()}
      </div>
      <PremiumToolbox
        className={styles.premiumBanner}
        onGetPremiumClicked={() => {
          setTimeout(() => document.activeElement.blur(), 1);
          segmentTracking('get_premium clicked', {
            location: 'toolbox',
          });
        }}
        onSuccess={() => {
          console.log('SUCCESS CLICKED');
        }}
        user={user}
      />
      <div className={styles.calculatorsContainer}>
        <h3>TurboTenant Tools & Calculators</h3>
        <p>Strategize and save time with our handy tools.</p>
        <div className={styles.calculatorsInnerContainer}>
          <OptionItemCard
            Icon={LeaseAI}
            iconProps={{ height: 48, width: 48 }}
            className={styles.calculatorItem}
            title="Lease Agreement AI Audit Tool"
            description="In seconds, our free tool analyzes your lease for compliance and more."
            onClick={() => window.open('/lease-agreement-audit-ai', '_blank')}
          />
          <OptionItemCard
            Icon={DollarDocumentColor}
            iconProps={{ width: 28.12, height: 38.79 }}
            className={styles.calculatorItem}
            title="Rent Estimate Calculator"
            description="See what similar rental properties in your area are charging for rent."
            onClick={() =>
              window.open(
                'https://rental.turbotenant.com/rent-estimate-report',
                '_blank',
              )
            }
          />
          <OptionItemCard
            Icon={PurchaseHouse}
            iconProps={{ height: 48, width: 48 }}
            className={styles.calculatorItem}
            title="Rental Property Calculator"
            description="Calculate the ROI on a rental property you want to buy."
            onClick={() =>
              window.open(
                'https://www.turbotenant.com/rental-property-calculator/',
                '_blank',
              )
            }
          />
          <OptionItemCard
            Icon={CalendarColored}
            iconProps={{ height: 38.79 }}
            className={styles.calculatorItem}
            title="Prorated Rent Calculator"
            description="Easily determine the prorated rent amount for a move-in or move-out date."
            onClick={() =>
              window.open(
                'https://www.turbotenant.com/prorated-rent-calculator/',
                '_blank',
              )
            }
          />
          <OptionItemCard
            Icon={PieChart}
            iconProps={{ height: 38.79 }}
            className={styles.calculatorItem}
            title="Rent-to-Income Ratio Calculator"
            description="Easily calculate how much rent a tenant can afford based on their monthly income."
            onClick={() =>
              window.open(
                'https://www.turbotenant.com/rent-to-income-ratio-calculator/',
                '_blank',
              )
            }
          />
          <OptionItemCard
            Icon={Subscription}
            iconProps={{ height: 38.79 }}
            className={styles.calculatorItem}
            title="Rental Cashflow Calculator"
            description="Enhance your investment strategy by evaluating your property's profitability."
            onClick={() =>
              window.open(
                'https://www.turbotenant.com/landlord-toolbox/rental-cashflow-calculator/#:~:text=Get%20Started%20Today-,Rental%20Cashflow%20Calculator,-Property%20Details',
                '_blank',
              )
            }
          />
        </div>
      </div>
      <ReiHubLoadingModal
        open={isModalOpen}
        isDemoMode={!user.reihub_subscription_subscribed}
      />
    </LoadingScreen>
  );
};

export default ToolboxPage;
